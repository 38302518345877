import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  VStack,
  Text,
  Icon,
  Link as ChakraLink,
  useColorModeValue,
  Image,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Flex,
  HStack,
  IconButton,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import { FaHome, FaSignOutAlt, FaSignInAlt, FaExchangeAlt, FaNewspaper, FaUniversity, FaEnvelope, FaBook, FaBars, FaUser, FaRobot, FaChartLine, FaLightbulb, FaCoins, FaMoneyBillWave, FaShieldAlt, FaKey } from 'react-icons/fa';
import { useAuth0 } from '@auth0/auth0-react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

function Navigation() {
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 48em)");
  const location = useLocation();

  const linkColor = useColorModeValue('gray.600', 'gray.400');
  const activeLinkColor = useColorModeValue('white', 'white');
  const activeLinkBg = useColorModeValue('teal.500', 'teal.600');
  const sidebarBg = useColorModeValue('rgba(255, 255, 255, 0.9)', 'rgba(26, 32, 44, 0.9)');
  const hoverBgColor = useColorModeValue('rgba(237, 242, 247, 0.8)', 'rgba(45, 55, 72, 0.8)');
  const shadowColor = useColorModeValue('rgba(0, 0, 0, 0.1)', 'rgba(255, 255, 255, 0.1)');
  const dividerColor = useColorModeValue('gray.200', 'gray.600');
  const scrollbarThumbColor = useColorModeValue('teal.500', 'teal.200');

  const navCategories = [
    {
      title: "Accueil",
      items: [
        { icon: FaHome, label: 'Tableau de bord', path: '/' },
      ]
    },
    {
      title: "Investissements",
      items: [
        { icon: FaExchangeAlt, label: 'Fonds/ETFs', path: '/liste-meilleurs-fonds-et-etf' },
        { icon: FaUniversity, label: 'Obligations', path: '/bonds' },
        { icon: FaChartLine, label: 'Gestion pilotée', path: '/gestion-pilotee-sous-mandat/' },
      ]
    },
    {
      title: "Épargne",
      items: [
        { icon: FaShieldAlt, label: 'Assurance vie', path: '/assurance-vie'},
        { icon: FaKey, label: 'Livret A', path: '/livret-a'},
        { icon: FaMoneyBillWave, label: 'PER', path: '/plan-epargne-retraite'},
        { icon: FaCoins, label: 'PEA', path: '/plan-epargne-action'},
      ]
    },
    {
      title: "Actualités & Analyse",
      items: [
        { icon: FaNewspaper, label: 'Actualités boursières', path: '/news' },
        { icon: FaBook, label: 'Articles', path: '/articles' },
      ]
    },
    {
      title: "Outils IA",
      items: [
        { icon: FaRobot, label: 'FinGPT', path: '/chatbot' },
        { icon: FaLightbulb, label: 'Idées d\'Investissement', path: '/investment-ai' },
      ]
    },
    {
      title: "Mon Compte",
      items: [
        { icon: FaUser, label: 'Profil', path: '/profil-client' },
        { icon: FaEnvelope, label: 'Nous contacter', path: '/contact' },
        isAuthenticated && { icon: FaSignOutAlt, label: 'Déconnexion', path: '/logout', action: () => logout({ returnTo: window.location.origin }) },
      ].filter(Boolean),
    },
  ];

  const handleNavigation = (path, action) => {
    if (action) {
      action();
    } else {
      navigate(path);
    }
    if (isMobile) onClose();
  };

  const isActive = (path) => {
    if (path === '/logout') {
      return false;
    }
    return location.pathname === path;
  };

  const AuthButtons = () => (
    !isAuthenticated && (
      <Button
        as={motion.button}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        variant="solid"
        colorScheme="teal"
        leftIcon={<FaSignInAlt />}
        onClick={() => loginWithRedirect()}
        borderRadius="full"
        boxShadow="md"
        _hover={{ boxShadow: 'lg' }}
      >
        Connexion
      </Button>
    )
  );

  const SidebarContent = () => (
    <VStack spacing={6} align="stretch" width="100%" mt={4}>
      {navCategories.map((category, index) => (
        <Box key={index}>
          <Text fontSize="sm" fontWeight="bold" color="teal.500" mb={2} textTransform="uppercase" letterSpacing="wide">
            {category.title}
          </Text>
          {category.items.map((item) => (
            <ChakraLink
              key={item.label}
              as={motion.div}
              whileHover={{ x: 5 }}
              px={3}
              py={2}
              display="flex"
              alignItems="center"
              borderRadius="md"
              bg={isActive(item.path) ? activeLinkBg : 'transparent'}
              color={isActive(item.path) ? activeLinkColor : linkColor}
              fontWeight="medium"
              transition="all 0.3s"
              _hover={{ bg: hoverBgColor, textDecoration: 'none' }}
              onClick={() => handleNavigation(item.path, item.action)}
            >
              <Icon as={item.icon} mr={3} />
              {item.label}
            </ChakraLink>
          ))}
          {index < navCategories.length - 1 && <Divider my={4} borderColor={dividerColor} />}
        </Box>
      ))}
      {isMobile && (
        <VStack spacing={4} align="stretch">
          <AuthButtons />
        </VStack>
      )}
    </VStack>
  );

  return (
    <>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="0.5rem 1rem"
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex="docked"
        boxShadow={`0 2px 10px ${shadowColor}`}
        width="100%"
      >
        <Flex align="center" mr={5}>
          <MotionBox
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Image
              src="https://i.ibb.co/wM10kzn/DALL-E-2024-04-24-12-35-removebg-preview.png"
              alt="CGP Logo"
              boxSize="40px"
              mr={2}
            />
          </MotionBox>
          <Text fontSize="xl" fontWeight="bold" color={useColorModeValue('teal.600', 'teal.200')}>
            PatrimoMarket
          </Text>
        </Flex>

        <HStack spacing={4}>
          <AuthButtons />
          {isMobile && (
            <IconButton
              aria-label="Open menu"
              icon={<FaBars />}
              onClick={onOpen}
              variant="ghost"
              color={linkColor}
              _hover={{ bg: hoverBgColor }}
              _active={{ bg: activeLinkBg, color: activeLinkColor }}
            />
          )}
        </HStack>
      </Flex>

      {isMobile ? (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay>
            <DrawerContent bg={sidebarBg} boxShadow={`2px 0 10px ${shadowColor}`}>
              <DrawerCloseButton />
              <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader>
              <DrawerBody>
                <SidebarContent />
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      ) : (
        <Box
          bg={sidebarBg}
          w={250}
          px={4}
          py={6}
          boxShadow={`2px 0 10px ${shadowColor}`}
          h="calc(100vh - 60px)"
          position="fixed"
          top="60px"
          left={0}
          overflowY="auto"
          sx={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: scrollbarThumbColor,
              borderRadius: '24px',
            },
          }}
        >
          <SidebarContent />  
        </Box>
      )}
    </>
  );
}

export default Navigation;